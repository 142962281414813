
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class ValidateStaff extends mixins(
  Navigation,
  FormValidations,
  StyleCheck
) {
  $refs!: {
    changePasswordForm: HTMLFormElement;
  };
  user = {
    password: "",
  };
  loading = false;
  showPassword = false;
  showPasswordCheck = false;
  showErrorPassword = true;
  verifyUserPassword = "";
  minLengthPassword = process.env.VUE_APP_MIN_LENGTH_PASSWORD;
  passwordValidations = {
    minLength: false,
    minSpecial: false,
    minNumber: false,
    minCapitalLetter: false,
    noEmptySpaces: false,
    equals: false,
  };

  private checkUserPassword(): boolean {
    if (
      this.passwordValidations.minLength &&
      this.passwordValidations.minSpecial &&
      this.passwordValidations.minNumber &&
      this.passwordValidations.minCapitalLetter &&
      this.passwordValidations.noEmptySpaces &&
      this.passwordValidations.equals
    ) {
      return true;
    } else {
      return false;
    }
  }

  @Watch("user.password")
  @Watch("verifyUserPassword")
  verifyEqualsPasswords(): void {
    if (
      this.user.password == this.verifyUserPassword &&
      this.user.password != "" &&
      this.verifyUserPassword != "" &&
      this.user.password != null &&
      this.verifyUserPassword != null
    ) {
      this.passwordValidations.equals = true;
    } else {
      this.passwordValidations.equals = false;
    }
  }

  @Watch("user.password")
  verifyMinLength(value: string): void {
    if (value != null) {
      if (value.length >= process.env.VUE_APP_MIN_LENGTH_PASSWORD) {
        this.passwordValidations.minLength = true;
      } else {
        this.passwordValidations.minLength = false;
      }
    }
  }

  @Watch("user.password")
  verifyMinCapitalLetter(value: string): void {
    if (/[A-Z]/.test(value)) {
      this.passwordValidations.minCapitalLetter = true;
    } else {
      this.passwordValidations.minCapitalLetter = false;
    }
  }

  @Watch("user.password")
  verifyMinNumber(value: string): void {
    if (/[0-9]/.test(value)) {
      this.passwordValidations.minNumber = true;
    } else {
      this.passwordValidations.minNumber = false;
    }
  }

  @Watch("user.password")
  verifyNoEmptySpaces(value: string): void {
    if (/[ \t\r\n\f]/.test(value)) {
      this.passwordValidations.noEmptySpaces = false;
    } else {
      this.passwordValidations.noEmptySpaces = value != null ? true : false;
    }
  }

  @Watch("user.password")
  verifyMinSpecial(value: string): void {
    if (/[#$%&/=*!¡?¿]/.test(value)) {
      this.passwordValidations.minSpecial = true;
    } else {
      this.passwordValidations.minSpecial = false;
    }
  }

  @Watch("user.password")
  @Watch("verifyUserPassword")
  hideErrorPassword(): void {
    if (this.checkUserPassword()) {
      this.showErrorPassword = false;
    } else {
      this.showErrorPassword = true;
    }
  }

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  @Watch("language")
  resetValidations() {
    this.resetFormValidations([this.$refs.changePasswordForm]);
  }

  private async submit() {
    if (this.$refs.changePasswordForm.validate()) {
      this.loading = true;
      await this.$store
        .dispatch("authentication/staffValidate", {
          password: this.user.password,
          token: this.$route.params.token,
        })
        .then(() => {
          let message: string = this.$tc("SetPasswordStaff.success");
          const SuccessNotification: Notification = {
            message: message,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            persistent: true,
            error: false,
          };

          this.$store.dispatch(
            "notifications/showNotification",
            SuccessNotification
          );
          this.loading = false;
          if (this.$route.query.redirection) {
            this.navigate(this.$route.query.redirection as string);
          } else {
            this.navigate("/sign-in");
          }
        })
        .catch(() => {
          let errorMessage: string = this.$tc("SetPasswordStaff.error");
          const Error: Notification = {
            message: errorMessage,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
